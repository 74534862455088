import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="To Do List Website Template | Uizard"
    category="Web App Templates"
    url="/templates/web-app-templates/to-do-web-app/"
    metaDescription="Design the perfect to-do list app with Uizard today. With our stunning app template, you can bring your web app design to life in minutes.."
    description="
    h2:Organic Mind: the ultimate to do list website design template
    <br/>
    Designing a task list web app from scratch? Take it off your to-do list now and use our stunning Organic Mind design template instead. Created to help you easily visualize your to-do list web app design in minutes, Organic Mind is fully customizable and boasts a number of straight-out-the-box, must-have features.
    <br/>
    h3:Creating a to-do list web app design has never been easier
    <br/>
    For every minute spent organizing, an hour is earned… As with all our <a:https://uizard.io/templates/web-app-templates/>web design templates</a>, our to-do list template is designed to save you time and support you to create the best possible version of your web app prototype design in minutes. Build a streamlined and aesthetically powerful to-do list web app design prototype with Uizard now.
    <br/>
    h3:Create your to-do list web app design using Organic Mind
    <br/>
    With predefined screens and natural hyperlinks to demonstrate how your users will navigate through your interface, Organic Mind comes equipped with all the must-haves for your to-do list app design. To get started, simply open the template, and adapt it to suit your needs. Join the magical <a:https://uizard.io/prototyping/>UI prototyping</a> revolution today.
    "
    pages={[
      "A stunning, minimalist web app homepage",
      "A log-in page with clear and simple calls to action",
      "An in-app interface page, demonstrating task list visualization, with task detail popups on click",
    ]}
    projectCode="XXJOvmKW0jhEyYZdmA7w"
    img1={data.image1.childImageSharp}
    img1alt="to do web app design template cover"
    img2={data.image2.childImageSharp}
    img2alt="to do web app design template calendar screen"
    img3={data.image3.childImageSharp}
    img3alt="to do web app design template sticky wall screen"
    img4={data.image4.childImageSharp}
    img4alt="to do web app design template upcoming screen"
    img5={data.image5.childImageSharp}
    img5alt="to do web app design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/to-do-web-app/to-do-web-app-cover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: { eq: "templates/to-do-web-app/to-do-web-app-calendar.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/to-do-web-app/to-do-web-app-sticky-wall.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/to-do-web-app/to-do-web-app-upcoming.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: { eq: "templates/to-do-web-app/to-do-web-app-summary.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
